import './reset.css';
import './space.css';
import './App.css';

function App() {
    return (
        <div className="App">
            <div className="content">
                <div className="pre-header sp-bot-64">
                </div>
                <div className="logo sp-bot-16">
                    <img src="kupo-v3.png" className="logo-img" alt="logo" />
                </div>
                <div className="header sp-bot-32">
                    <h1 className="sp-bot-8">
                        Jonathan Pham
                    </h1>
                    <h2 className="sp-bot-8">
                        aka @kupopuffs
                    </h2>
                    <h3 className="sp-bot-8">
                        <a href="mailto:pjonathanh@gmail.com">pjonathanh [@] gmail.com</a>
                    </h3>
                </div>
                <div className="check-out sp-bot-64">
                    <p className="sp-bot-16">
                        check out my
                    </p>
                    <div className="socials-container sp-bot-32">
                        <span className="socials-col">
                            <a href="https://github.com/kupopuffs" target="_blank" alt="github-link">
                                <img className="socials-logo" src="github.png" alt="github-logo" />
                                <p>
                                    code
                                    <img className="link-img" src="link.png" alt="link" />
                                </p>
                            </a>
                        </span>
                        <span className="socials-col">
                            <a href="https://www.instagram.com/kupopharts" target="_blank" alt="instagram-link">
                                <img className="socials-logo" src="instagram.png" alt="instagram-logo" />
                                <p>
                                    art
                                    <img className="link-img" src="link.png" alt="link" />

                                </p>
                            </a>
                        </span>
                        <span className="socials-col">
                            <a href="https://www.linkedin.com/in/jonathan-pham-18037797/" target="_blank" alt="linkedin-link">
                                <img className="socials-logo" src="linkedin.png" alt="linkedin-logo" />
                                <p>
                                    work
                                    <img className="link-img" src="link.png" alt="link" />

                                </p>
                            </a>
                        </span>
                    </div>
                </div>
                <div className="post-footer">
                    <a className="privacy-text sp-bot-16" href="privacy.txt" target="_blank" alt="privacy-link">
                        privacy.txt
                    </a>
                </div>
            </div>
        </div>
    );
}

export default App;
